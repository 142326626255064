import { DefaultHandler } from "./DefaultHandler";
import _ from "lodash";

class MaterialOutwardHandler extends DefaultHandler {
  getFormWidth(): string {
    return "80vw";
  }
  getEntityType(): string {
    return "material-outward";
  }

  isValidRecord = (record, setFormErrors, setError) => {
    if (!record) {
      return false;
    }
    if (!record.details || _.isEmpty(record.details)) {
      return true;
    }
    let mirAvailableQuantitesValid = true;
    record.details.forEach((detail, i) => {
      if (parseFloat(detail.mirAvailableQuantity) < parseFloat(detail.totalQuantity)) {
        setError(
          `Selected Quantity: ${parseFloat(detail.totalQuantity)} is greater than available quantity: ${parseFloat(
            detail.mirAvailableQuantity
          )} for MIR Row Id: ${detail.mirRowId}`
        );
        mirAvailableQuantitesValid = false;
        setFormErrors((formError) => {
          formError["details"] = formError["details"] ? formError["details"] : [];
          formError["details"][i] = formError["details"][i] ? formError["details"][i] : {};
          formError["details"][i]["totalQuantity"] = "Error";
          return { ...formError };
        });
        return;
      }
    });
    if (mirAvailableQuantitesValid) {
      setError(null);
    }
    return mirAvailableQuantitesValid;
  };

  handleEntitySpecificChange = async ({ record, name, value, type, checked, setFormErrors, setError, handleRecordChange }) => {
    if (name === "transactionType") {
      if (value === "CS" && record.projectType === "FTTH") {
        handleRecordChange({
          target: { name: "receiverDocType", value: "CO", type, checked },
        });
      } else {
        handleRecordChange({
          target: { name: "receiverDocType", value: "NA", type, checked },
        });
      }
    } else if (name === "projectType") {
      if (value === "FTTH") {
        if (record.transactionType === "CS") {
          handleRecordChange({
            target: { name: "receiverDocType", value: "CO", type, checked },
          });
        } else if (record.docLocatorType === "CS") {
          handleRecordChange({
            target: { name: "senderDocType", value: "CO", type, checked },
          });
        } else {
          handleRecordChange({
            target: { name: "senderDocType", value: "NA", type, checked },
          });
        }
      } else {
        handleRecordChange({
          target: { name: "senderDocType", value: "NA", type, checked },
        });
      }
    }
    if (name === "details" && value.length === 1) {
      // let request = {
      //   query: "",
      //   filter: {},
      //   lookupCategory: "outwardItem",
      //   lookupParams: {
      //     docLocatorCode: record.docLocatorCode,
      //     materialType: record.materialType,
      //     projectCode: record.projectCode,
      //     selectedMirRowIds: [],
      //     outwardDocDate: record.docDate,
      //   },
      // };
      // let resp = await apiCall("post", `/api/entity/item/lookup`, request);
      // if (!resp || !resp.results || resp.results.length === 0) {
      //   setError("No valid item for outward found for the selected locator and project. Please select another locator or project. Or check the inward details are valid and approved/submitted.");
      // }
    }
  };
}
const materialOutwardHandler = new MaterialOutwardHandler();
export default materialOutwardHandler;
