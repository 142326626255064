import React, { useState, useEffect } from "react";
import RecordPage from "./RecordPage";
import RecordTabularView from "./RecordTabularView";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import { getSearchColumnsForRecordType } from "../../services/standardEntityFieldService";
import { getDefaultValuesForFields } from "../../services/fieldsUtils";
import dayjs from "../../config/dayjsConfig";
import { canEditEntity, canViewEntity } from "../../services/utils";
import withAuth from "../../hocs/withAuth";
import { getOpeningStockDate } from "../../services/dateUtils";
import RecordAdditionForm from "./RecordAdditionForm";

function RecordManager({ searchFields, entityType, pageTitle, currentUser }) {
  let { recordType, mode: urlMode } = useParams<{ recordType: string; mode: string }>();
  recordType = entityType ? entityType : recordType;
  if (_.isEmpty(searchFields)) {
    searchFields = getSearchColumnsForRecordType(recordType);
  }
  const today = new Date(); // Current date and time
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);
  let defaultValues = getDefaultValuesForFields(searchFields);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [mode, setMode] = useState(urlMode || "view");
  const [recordSearchRequest, setRecordSeachRequest] = useState(defaultValues);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(getOpeningStockDate());
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs(today));
  const [refreshKey, setRefreshKey] = useState(0); // State variable to trigger re-fetching
  const history = useHistory();

  useEffect(() => {
    if (!canViewEntity(recordType, currentUser)) {
      history.push("/");
    }
  }, [recordType, currentUser, history]);

  useEffect(() => {
    setSelectedRecord(null);
    setRecordSeachRequest(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordType, entityType]);

  useEffect(() => {
    setMode(urlMode || "view");
  }, [urlMode]);

  const handleEndDateChange = (date) => {
    let newValue: dayjs.Dayjs;
    setEndDate((oldValue) => {
      try {
        let finalValue = date.toISOString();
        newValue = dayjs(finalValue);
      } catch (e) {
        newValue = dayjs(today);
      }
      return newValue;
    });
  };

  const handleStartDateChange = (date) => {
    let newValue: dayjs.Dayjs;
    setStartDate((oldValue) => {
      try {
        let finalValue = date.toISOString();
        newValue = dayjs(finalValue);
      } catch (e) {
        newValue = dayjs(today);
      }
      return newValue;
    });
  };

  const incrementRefreshKey = () => {
    setRefreshKey((oldKey) => oldKey + 1);
  };

  if (mode === "edit" && !selectedRecord) {
    history.push(`/${recordType}`);
  }

  //todo : remove set mode and replace by url mode itself
  return (
    <>
      {selectedRecord && mode === "edit" && (
        <RecordAdditionForm
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          setMode={setMode}
          originalIsUpdateForm={true}
          incrementRefreshKey={incrementRefreshKey}
        />
      )}
      {selectedRecord && mode === "view" && (
        <RecordPage
          recordId={selectedRecord.id}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          isEditable={() => canEditEntity(recordType, currentUser)}
          creationAllowed={canEditEntity(recordType, currentUser)}
          setMode={setMode}
        />
      )}
      {mode === "add" && (
        <RecordAdditionForm
          selectedRecord={null}
          setSelectedRecord={setSelectedRecord}
          setMode={setMode}
          originalIsUpdateForm={false}
          incrementRefreshKey={incrementRefreshKey}
        />
      )}
      {!selectedRecord && mode !== "add" && (
        <RecordTabularView
          key={recordType}
          setSelectedRecord={setSelectedRecord}
          recordSearchRequest={recordSearchRequest}
          setRecordSeachRequest={setRecordSeachRequest}
          entityType={entityType}
          pageTitle={pageTitle}
          startDate={startDate}
          handleStartDateChange={handleStartDateChange}
          endDate={endDate}
          handleEndDateChange={handleEndDateChange}
          creationAllowed={canEditEntity(recordType, currentUser)}
          refreshKey={refreshKey} // Pass the refresh key
        />
      )}
    </>
  );
}

export default withAuth(RecordManager);
