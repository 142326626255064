import { DefaultHandler } from "./DefaultHandler";
class GatePassHandler extends DefaultHandler {
  getFormWidth(): string {
    return "80vw";
  }
  getEntityType(): string {
    return "gate-pass";
  }
}
const gatePassHandler = new GatePassHandler();
export default gatePassHandler;
