import { Field } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";
import { formatDate, getOpeningStockDate } from "../services/dateUtils";

export const projectTypeValues = [
  { value: "FF", label: "FF Work" },
  { value: "FTTH", label: "FTTH Work" },
];

export const cwoTypeValues = [
  { value: "OVH", label: "Over Head" },
  { value: "UGD", label: "Under Ground" },
  { value: "MIX", label: "Mixed" },
  { value: "IBD", label: "IBD" },
  { value: "TPT", label: "TPT" },
  { value: "RHB", label: "Rehab" },
  { value: "DHK", label: "De-Choking" },
  { value: "CTV", label: "Cut Over" },
  { value: "OTH", label: "Others" },
];

export const childWorkOrderFields: Field[] = [
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "projectName",
        label: "Project Name",
        type: "lookup",
        lookupType: "project",
        lookupCategory: "workProjects",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "locatorStateCode",
            },
            {
              type: "IN",
              field: "projectType",
              values: ["FTTH"],
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "projectCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "stateCode",
            fieldName: "stateCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "stateName",
            fieldName: "stateName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "projectType",
            fieldName: "projectType",
          },
        ],
        props: {
          width: "17vw",
        },
      },
      {
        name: "projectCode",
        label: "Project Code",
        type: "text",
        autoFilled: true,
        props: {
          width: "7vw",
        },
      },
    ],
  },
  // {
  //   name: "section1",
  //   type: "formSection",
  //   childFields: [
  //     {
  //       name: "projectType",
  //       label: "Project Type",
  //       type: "picklist",
  //       defaultValue: "",
  //       autoFilled: true,
  //       values: [
  //         { value: "FF", label: "FF Work" },
  //         { value: "FTTH", label: "FTTH Work" },
  //       ],
  //       props: {
  //         width: "17vw",
  //       },
  //     },
  //     {
  //       name: "projectCode",
  //       label: "Project Code",
  //       type: "text",
  //       autoFilled: true,
  //       props: {
  //         width: "7vw",
  //       },
  //     },
  //   ],
  // },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "cwoType",
        label: "CWO Type",
        type: "picklist",
        allowUpdate: true,
        defaultValue: "",
        values: cwoTypeValues,
      },
      {
        name: "stateName",
        label: "State Name",
        type: "text",
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "cwoNo",
    label: "CWO No",
    type: "text",
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "cwoDate",
        label: "CWO Date",
        type: "date",
        defaultValue: "",
        constraints: [
          {
            type: "DATE_RANGE",
            minDate: getOpeningStockDate(),
            beforeNow: true,
          },
        ],
      },
      {
        name: "id",
        label: "CWO Id",
        type: "number",
        constraints: [{ type: "RANGE", minLimit: 0 }],
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "workArea",
        label: "Work Area",
        type: "text",
        allowUpdate: true,
        constraints: [{ type: "LENGTH", maxLength: 50 }],
        defaultValue: "",
        props: {
          width: "17vw",
        },
      },
      {
        name: "gisCode",
        label: "Gis Code",
        type: "text",
        allowUpdate: true,
        defaultValue: "",
        props: {
          width: "7vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "homePass",
        label: "Home Pass",
        type: "number",
        allowUpdate: true,
        defaultValue: "",
      },
      {
        name: "routeLength",
        label: "Route Length",
        type: "number",
        allowUpdate: true,
        defaultValue: "",
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "locatorName",
        label: "Locator Name",
        props: {
          width: "17vw",
        },
        type: "lookup",
        lookupType: "locator",
        lookupFieldName: "locatorCode",
        lookupCategory: "docLocator",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "locatorType",
              values: ["CS"],
            },
            {
              type: "EQUALS",
              field: "locatorCode",
              resolveValue: true,
              valueFieldName: "locatorCode",
            },
            {
              type: "EQUALS",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "stateCode",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "locatorCode",
          },
          {
            additionalFieldName: "contractor",
            lookupFieldName: "companyCode",
            fieldName: "contractorCode",
          },
          {
            additionalFieldName: "contractor",
            lookupFieldName: "companyName",
            fieldName: "contractorName",
          },
        ],
      },
      {
        name: "locatorCode",
        label: "Locator",
        type: "text",
        props: {
          width: "7vw",
        },
        autoFilled: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "contractorCode",
        label: "Contractor Code",
        type: "text",
        props: {
          width: "7vw",
        },
        autoFilled: true,
        allowNull: true,
      },
      {
        name: "contractorName",
        label: "Contractor Name",
        type: "text",
        props: {
          width: "17vw",
        },
        // type: "lookup",
        // lookupType: "company",
        // lookupCategory: "contractorCompany",
        // lookupFilter: { type: "AND", filters: [{ type: "IN", field: "contractorFlg", values: [true] }] },
        // props: {
        //   width: "17vw",
        // },
        // dependentFields: [
        //   {
        //     lookupFieldName: "id",
        //     fieldName: "contractorCode",
        //   },
        // ],
      },
    ],
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
    allowUpdate: true,
    allowNull: true,
    props: {
      width: "25vw",
    },
  },
  {
    name: "section4",
    type: "formSection",
    childFields: [
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        allowUpdate: true,
        defaultValue: false,
      },
      {
        name: "closed",
        label: "Closed",
        type: "checkbox",
        displayOnly: true,
        allowUpdate: false,
        defaultValue: false,
      },
    ],
  },
];

export const childWorkOrderColumns = [
  {
    name: "id",
    label: "CWO Id",
    type: "text",
  },
  {
    name: "cwoDate",
    label: "CWO Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "cwoType",
    label: "CWO Type",
    type: "text",
    format: (value) => translatePicklistValue(value, cwoTypeValues),
  },
  // {
  //   name: "cwoNo",
  //   label: "CWO No",
  //   type: "text",
  // },
  {
    name: "homePass",
    label: "Home Pass",
    type: "number",
  },
  {
    name: "routeLength",
    label: "Route Length",
    type: "number",
  },
  {
    name: "projectCode",
    label: "Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "workArea",
    label: "Work Area",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "locatorCode",
    label: "Locator",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const childWorkOrderExcelColumns = [
  {
    name: "id",
    label: "CWO Id",
    type: "text",
  },
  {
    name: "cwoDate",
    label: "CWO Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "cwoType",
    label: "CWO Type",
    type: "text",
    format: (value) => translatePicklistValue(value, cwoTypeValues),
  },
  {
    name: "cwoNo",
    label: "CWO No",
    type: "text",
  },
  {
    name: "homePass",
    label: "CWO Home Pass",
    type: "number",
  },
  {
    name: "routeLength",
    label: "CWO Route Length",
    type: "number",
  },
  {
    name: "workArea",
    label: "Work Area",
    type: "text",
  },
  {
    name: "gisCode",
    label: "GIS Code",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "locatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "contractorCode",
    label: "Contractor Code",
    type: "text",
  },
  {
    name: "contractorName",
    label: "Contractor Name",
    type: "text",
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const childWorkOrderSearchColumns = [
  {
    name: "cwoId",
    label: "CWO Id",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  // {
  //   name: "cwoType",
  //   label: "CWO Type",
  //   type: "picklist",
  //   defaultValue: "",
  //   values: cwoTypeValues,
  // },
  // {
  //   name: "cwoNo",
  //   label: "CWO No",
  //   type: "text",
  //   props: {
  //     widthFactor: 2,
  //   },
  // },
  {
    name: "childWorkOrder.projectCode",
    label: "Code",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "workArea",
    label: "Work Area",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "childWorkOrder.locatorCode",
    label: "Locator",
    type: "text",
  },
  // {
  //   name: "locatorName",
  //   label: "Locator Name",
  //   type: "text",
  // },
  {
    name: "companyName",
    label: "Contractor",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "closed",
    label: "Closed",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];
