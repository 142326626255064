import { EntityHandler, HandleEntitySpecificChangeDTO } from "./types";
import { transactionEntities } from "../../services/standardEntityFieldService";

export class DefaultHandler implements EntityHandler {
  getFormWidth(): string {
    return "40vw";
  }
  formDisabled(record: any): boolean {
    return false;
  }
  handleEntitySpecificChange(handleEntitySpecificChangeDTO: HandleEntitySpecificChangeDTO): Promise<void> {
    return;
  }
  isValidRecord(record: any, setFormErrors: () => {}, setError: () => {}): boolean {
    return true;
  }

  isUnSubmitRequired = (isApproved: boolean) => {
    return isApproved;
  };
  canDelete = (recordType, currentUser, record) => !transactionEntities.includes(recordType);

  getEntityType(): string {
    throw new Error("Unsupported function for default handler");
  }
}

const defaultHandler = new DefaultHandler();
export default defaultHandler;
