import { DefaultHandler } from "./DefaultHandler";
import { User } from "../../components/users/UserTypes";
class UserHandler extends DefaultHandler {
  override isUnSubmitRequired = () => false;
  override canDelete = (recordType, currentUser, user: User) => {
    return user.approvedAt == null;
  };
  override getEntityType(): string {
    return "user";
  }
}
const userHandler = new UserHandler();
export default userHandler;
