import { DefaultHandler } from "./DefaultHandler";
class CBSheetHandler extends DefaultHandler {
  getFormWidth(): string {
    return "80vw";
  }
  getEntityType(): string {
    return "cb-sheet";
  }
}
const cbSheetHandler = new CBSheetHandler();
export default cbSheetHandler;
