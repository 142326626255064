import React, { useState, useEffect } from "react";
import withAuth from "../../hocs/withAuth";
import RecordPage from "../recordStandardForms/RecordPage";
import { useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { apiCall } from "../../services/api";
import { myProfileFields } from "../../standardEntities/user";
import RecordAdditionForm from "../recordStandardForms/RecordAdditionForm";
import { User } from "./UserTypes";
import Snackbar from "@mui/material/Snackbar";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ProfileViewForm({ currentUser }) {
  let { mode: urlMode } = useParams<{ mode: string }>();
  const location = useLocation();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [mode, setMode] = useState(urlMode || "view");

  // const isMyProfileViewPage: boolean = location.pathname === "/my-profile/view";
  function handleSnackBarClose() {
    setSnackBarMessage(null);
  }

  useEffect(() => {
    setMode(urlMode || "view");
  }, [urlMode]);

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    fetchAndUpdateCurrentUser();

    async function fetchAndUpdateCurrentUser() {
      setSelectedRecord(null);
      return apiCall("get", `/api/entity/user/${currentUser.id}`)
        .then((response) => {
          if (response == null) {
            console.warn("[MyProfileForm] Null response for records fetch");
            return;
          }
          return setSelectedRecord(response);
        })
        .catch((err) => {
          console.error("[MyProfileForm] Error fetching records", err);
          return null;
        });
    }
  }, [location.pathname, currentUser]);
  return (
    <>
      {selectedRecord && mode === "view" && (
        <RecordPage
          fields={myProfileFields}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          creationAllowed={false}
          entityType="my-profile"
          pageTitle={"My Profile"}
          currentUser={currentUser}
          hasBackButton={() => false}
          setMode={setMode}
        />
      )}
      {selectedRecord && mode === "edit" && (
        <RecordAdditionForm
          fields={myProfileFields}
          entityType="my-profile"
          originalPageTitle={"My Profile"}
          isUnSubmitRequired={() => false}
          canDelete={(recordType, currentUser, user: User) => false}
          selectedRecord={selectedRecord}
          originalIsUpdateForm={true}
          setSnackBarMessage={setSnackBarMessage}
          setMode={setMode}
          setSelectedRecord={setSelectedRecord}
        />
      )}
      {!selectedRecord && (
        <Box sx={{ ml: 22, display: "flex" }}>
          <CircularProgress />
        </Box>
      )}
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        message={snackBarMessage}
        action={action}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      />
    </>
  );
}

export default withAuth(ProfileViewForm);
